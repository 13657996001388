import { observable } from 'mobx';
import { Model, Store, Casts } from '../Base';
import { Driver } from '../Driver'
import { User } from '../User'
import { RejectionReason } from '../RejectionReason';
import { CalculationRuleStore } from '../CalculationRule';
import { CalculationRuleDescriptionStore } from '../CalculationRuleDescription';

export const STATUS_IN_PROGRESS = 'in progress';
export const STATUS_STUDENT = 'student';
export const STATUS_DRIVER = 'driver';
export const STATUS_EXIT = 'exit';
export const STATUS_REJECTED = 'rejected';
export const STATUS_INACTIVE = 'inactive';
export const STATUSES = [STATUS_IN_PROGRESS, STATUS_STUDENT, STATUS_DRIVER, STATUS_EXIT, STATUS_REJECTED, STATUS_INACTIVE];

export const REJECTION_REASON_MISSING_DOCUMENTS = 'missing documents';
export const REJECTION_REASON_DRIVING_SKILLS = 'driving skills';
export const REJECTION_REASON_LANGUAGE_SKILLS = 'language skills';
export const REJECTION_REASON_EXPERIENCE = 'experience';
export const REJECTION_REASONS = [REJECTION_REASON_MISSING_DOCUMENTS, REJECTION_REASON_DRIVING_SKILLS, REJECTION_REASON_LANGUAGE_SKILLS, REJECTION_REASON_EXPERIENCE];

export const EXIT_REASON_LACK_OF_COOPERATION = 'lack of cooperation';
export const EXIT_REASON_LOW_PERFORMANCE = 'low performance';
export const EXIT_REASON_FAILED_EXAM = 'failed exam';
export const EXIT_REASON_ALCOHOL = 'alcohol';
export const EXIT_REASON_FAMILY = 'family';
export const EXIT_REASON_HEALTH = 'health';
export const EXIT_REASON_LACK_OF_DEVELOPMENT = 'lack of development';
export const EXIT_REASON_PROBLEMS_WITH_DISPATCHER = 'problems with dispatcher';
export const EXIT_REASON_PROBLEMS_WITH_MENTOR = 'problems with mentor';
export const EXIT_REASON_RETIRED = 'retired';
export const EXIT_REASON_SALARY_DEDUCTION = 'salary - deduction';
export const EXIT_REASON_SALARY_SOCIAL_BENEFITS = 'salary - social benefits';
export const EXIT_REASON_SALARY_TOO_LOW = 'salary - too low';
export const EXIT_REASON_HOLIDAYS = 'holidays';
export const EXIT_REASON_UNKNOWN = 'unknown';
export const EXIT_REASONS = [EXIT_REASON_LACK_OF_COOPERATION, EXIT_REASON_LOW_PERFORMANCE, EXIT_REASON_FAILED_EXAM, EXIT_REASON_ALCOHOL, EXIT_REASON_FAMILY, EXIT_REASON_HEALTH, EXIT_REASON_LACK_OF_DEVELOPMENT, EXIT_REASON_PROBLEMS_WITH_DISPATCHER, EXIT_REASON_PROBLEMS_WITH_MENTOR, EXIT_REASON_RETIRED, EXIT_REASON_SALARY_DEDUCTION, EXIT_REASON_SALARY_SOCIAL_BENEFITS, EXIT_REASON_SALARY_TOO_LOW, EXIT_REASON_HOLIDAYS, EXIT_REASON_UNKNOWN];


export class DriverStatusChange extends Model {
    static backendResourceName = 'driver_status_change';

    @observable id = null;
    @observable status = null;
    @observable reason = '';
    @observable level = 1;
    @observable date = null;
    @observable createdAt = null;
    @observable exitReason = null;
    @observable dietPerDay = null;

    @observable rejectionExplanation = '';

    endDate(driverStatusChangeStore) {
        let sortedDriverStatusChangeStore = driverStatusChangeStore.toJS().sort((a, b) => {
            if(a.date === b.date && a.id === null){
                return b.id - a.id
            }else if(a.date === b.date && a.id !== null){
                return a.id - b.id
            }
            return new Date(a.date) - new Date(b.date)
        })

        let nextDriverStatusChange = null
        let endDate = new Date().toISOString().slice(0, 10)
        sortedDriverStatusChangeStore.forEach((driverStatus, index) => {
            if(driverStatus.id === this.toJS().id && sortedDriverStatusChangeStore.length > index+1){
                nextDriverStatusChange = sortedDriverStatusChangeStore[index+1]
                endDate = nextDriverStatusChange.date
            }
        })

        return endDate;
    }

    casts() {
        return {
            date: Casts.date,
            endDate: Casts.date,
            createdAt: Casts.date,
        };
    }

    relations() {
        return {
            driver: Driver,
            createdBy: User,
            rejectionReason: RejectionReason,
            calculationRules: CalculationRuleStore,
            choosenCalculationRuleDescriptions: CalculationRuleDescriptionStore,
        };
    }
}


export class DriverStatusChangeStore extends Store {
    Model = DriverStatusChange;
    static backendResourceName = 'driver_status_change';
}
