import i18next from 'i18next';
import en from 'i18n/translation/en';
import nl from 'i18n/translation/nl';

i18next.init({
    // Some tests rely that default lang is 'en'. One of those tests is
    // resetPassword.T31471.cy.js. There the page will rerender after
    // bootstrap is fetched if the lang is different than the default. This
    // causes the test to be flaky, depending if the bootstrap happens before
    // or after rendering the page.
    lng: 'en',
    resources: {
        en: { description: 'English', translation: en },
        nl: { description: 'Nederlands', translation: nl },
    },
    interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!

        // Upgrading i18next broke stuff, so set this option as it was in older
        // versions for compatibility (was false for <v21.0.0). See also
        // https://www.i18next.com/translation-function/interpolation
        skipOnVariables: false,
    },
});

export default i18next;
export const t = i18next.t.bind(i18next);
