import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { Image, Label, Modal, Transition, Icon, Popup, Table } from 'semantic-ui-react';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Route, withRouter } from 'react-router-dom';
import { MEANING_IDEA, MEANING_POSITIVE, MEANING_NEGATIVE } from 'store/BEPTicket';
import LogoName from 'image/logo-name.png';
import LogoBoek from 'image/logo-boek.png';
import LogoBoekChristmas from 'image/logo-boek-christmas.png';
import LogoCy from 'image/logo-cy.png';
import LogoArione from 'image/logo-arione.png';
import LogoKuba from 'image/logo-kuba.png';
import { hasPermission as permissionCheck, hasLowLevelPermission } from 'helpers/permission';
import ExactOnlineAuth from 'component/ExactOnlineAuth';
import { Link } from 'react-router-dom';
import { IconButton } from 'spider/semantic-ui/Button';
import { BEPTicketStore } from 'store/BEPTicket';
import { Activity } from 'store/Activity';
import CreateBEPDialog from 'component/BEPTicket/CreateDialog';
import styled from 'styled-components';
import subscribe from 'decorator/subscribe';
import moment from 'moment';
import { SystemHealth } from 'store/SystemHealth';
import RightDivider from 'spider/component/RightDivider';
import { SubmitButton } from 'spider/semantic-ui/Button';
import { DisputeStore } from '../store/Dispute';
import RDAnniverary from 'image/rd1yr.png'
import { BUILD_INFO } from 'helpers';
import { ParkingStore } from 'store/Parking';

const AbsoluteIcon = styled(Icon)`
    position: absolute;
    top: 8px;
    left: 8px;
`;

const LogoAbsoluteImg = styled.img`
    position: absolute;
    top: 8px;
    left: 8px;
`;

const LogoRDImg = styled.img`
    width: 80px;
    padding: 4px;
`

const LogoNameImg = styled.img`
    margin-left: 35px;
`;

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

@observer
class MyLogo extends Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
    };

    @observable visible = 'boek';
    @observable iconIndex = 0;

    isChristmas = moment().isBetween(`${moment().format('YYYY')}-12-20`, `${moment().format('YYYY')}-12-31`, undefined, '[]');

    aprilFoolsInterval = null;
    aprilFoolsSingleCycleInterval = null;
    aprilFoolsSingleImageTime = 3000;
    icons = ['boek', 'heart', 'cy', 'heart', 'arione', 'heart', 'kuba'];

    componentDidMount() {
        this.startAprilFools();
    }

    componentDidUpdate() {
        this.startAprilFools();
    }

    startAprilFools = () => {
        this.stopAprilFools();

        if (this.props.isLoggedIn && !permissionCheck(['activity.view_activity:customer']) && moment().format('DD-MM') === '01-04') {
            this.aprilFoolsInterval = setInterval(
                () => this.showAprilFoolsSingleCycleAnimation(),
                this.icons.length * this.aprilFoolsSingleImageTime + (20 + getRandomInt(30))
            );
        }
    }

    stopAprilFools = () => {
        clearInterval(this.aprilFoolsInterval);
        clearInterval(this.aprilFoolsSingleCycleInterval);
    }

    componentWillUnmount() {
        this.stopAprilFools();
    }

    showAprilFoolsSingleCycleAnimation() {
        this.aprilFoolsSingleCycleInterval = setInterval(() => {
            this.iconIndex = (this.iconIndex + 1) % this.icons.length;
            this.visible = this.icons[this.iconIndex];

            // Show only one full animation.
            if (this.iconIndex === 0) {
                clearInterval(this.aprilFoolsSingleCycleInterval);
            }
        }, this.aprilFoolsSingleImageTime); // Total animation duration: 3000 * icons.length
    }

    render() {

        return (
            <Logo>
                <Transition.Group animation="horizontal flip" duration={2000}>
                    {this.visible === 'boek' && <LogoAbsoluteImg src={this.isChristmas ? LogoBoekChristmas : LogoBoek} height="35" />}
                    {this.visible === 'heart' && <AbsoluteIcon fitted name="heart" color="red" />}
                    {this.visible === 'cy' && <LogoAbsoluteImg src={LogoCy} height="35" />}
                    {this.visible === 'arione' && <LogoAbsoluteImg src={LogoArione} height="35" />}
                    {this.visible === 'kuba' && <LogoAbsoluteImg src={LogoKuba} height="35" />}
                </Transition.Group>
                <LogoNameImg src={LogoName} height="35" />
            </Logo>
        );
    }
};

const StyledButton = styled(Button)`
    margin-top: 10px;
`;

const StyledLink = styled(Link)`
    margin-top: 13px;
    color: inherit;
`;

const StyledLabelInPopup = styled(Label)`
    top: -14px !important;
    left: unset !important;
    right: -9px !important;
`;

const StyledIconButton = styled(IconButton)`
    position: relative;
    display: flex !important;
    align-items: center !important;
    height: auto !important;
`;

@withRouter
@subscribe
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    @observable systemHealth = new SystemHealth();
    @observable bepModalIdeaOpen = false;
    @observable bepModalNegativeOpen = false;
    @observable bepModalPositiveOpen = false;
    @observable activityIdMilestone = null;
    @observable headerMilestoneText = '';
    @observable secondaryMilestoneText = '';
    @observable tertriaryMilestoneText = '';
    @observable imagePopupPath = '';


    @observable debug = false;
    @observable bepTicketCounts = {
        overdue: 0,
        total: 0,
    };

    @observable assignedDisputesCount = 0;
    @observable parkingsCount = 0;


    isAnniversary = moment().isBetween('2022-12-13', '2022-12-20', undefined, '[]');

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    handleAfterDeployNotification = e => {
        this.checkIfWasDeploy(e.data.after_deploy);
    };

    checkIfWasDeploy(afterDeploy) {
        const viewStore = this.props.store;

        if (afterDeploy !== false) {
            // Randomize new version notification so that users won't refresh
            // all at the same time and kill the server.
            setTimeout(() => {
                this.props.store.showNotification({
                    key: 'newAppVersion',
                    dismissAfter: false,
                    dismissible: false,
                    onClick: () => document.location.reload(),
                    message: t('form.notifications.newAppVersion'),
                });
            }, viewStore.newAppVersionRandomTimeout);
        }
    }

    handleAfterIsDeployingNotification = e => {
        this.checkIfIsDeploying(e.data.is_deploying);
    };

    checkIfIsDeploying(isDeploying) {
        if (isDeploying !== false) {
            setTimeout(() => {
                this.props.store.showNotification({
                    key: 'isDeploying',
                    dismissAfter: false,
                    dismissible: false,
                    message: t('form.notifications.isDeploying'),
                });
            }, 0);
        };
    };

    constructor(props) {
        super(props);

        this.debug = !!localStorage.getItem('debug');
    }

    componentDidMount() {
        const viewStore = this.props.store;
        const bepTicketStore = new BEPTicketStore();


        this.reaction = reaction(
            () => viewStore.isAuthenticated && !viewStore.currentUser.isCustomer,
            () => {
                console.log('componentDidMount', viewStore.isAuthenticated, !viewStore.currentUser.isCustomer)
                this.unsubscribe();

                this.subscribe({ '*PUBLIC*': 'after_deploy' }, this.handleAfterDeployNotification);
                this.subscribe({ '*PUBLIC*': 'is_deploying' }, this.handleAfterIsDeployingNotification);

                if (viewStore.isAuthenticated && !viewStore.currentUser.isCustomer) {
                    if(hasLowLevelPermission('iso_quality.view_bepticket')) {
                        bepTicketStore.fetchAssignedTicketCounts().then(
                            (data) => this.bepTicketCounts = data
                        );
                    }
                    if(!viewStore.currentUser.inGroup('admin') && !viewStore.currentUser.isSuperuser && (this.hasPermission(['invoice.manage_dispute:operations']) || this.hasPermission(['invoice.manage_dispute:dispatcher']))) {
                        const disputeStore = new DisputeStore();
                        disputeStore.fetchAssignedDisputesCount().then(
                            count => this.assignedDisputesCount = count
                        );
                    }
                    if(this.hasPermission(['boekestijn.manage_parking:all'])) {
                        const parkingStore = new ParkingStore();
                        parkingStore.fetchParkingsCount().then(
                            count => this.parkingsCount = count
                        );
                    }
                    this.registerSubscriptions();
                }
            }
        );
    }

    componentWillUnmount() {
        this.reaction();
    }

    registerSubscriptions() {
        console.log('registerSubscriptionsregisterSubscriptionsregisterSubscriptions')
        this.props.store.allowedRooms.forEach(allowedRoom => {
            if (allowedRoom.target === 'bep-ticket-create') {
                this.subscribe(
                    allowedRoom, this.bepTicketCreated.bind(this)
                );
            }
        });

        this.props.store.allowedRooms.forEach(allowedRoom => {
            if (allowedRoom.target === 'bep-ticket-update') {
                this.subscribe(
                    allowedRoom, this.bepTicketUpdated.bind(this)
                );
            }
        });
        this.subscribe(
            { target: 'fleetvisor-mailbox' },
            data => {
                this.systemHealth.fleetvisor = data.data.events
                this.systemHealth.fleetvisorSummary = data.data.summary
            }
        );
        this.subscribe(
            { target: 'ptv-health-summary' },
            data => {
                console.log('ptv-health-summary', data.data)
                this.systemHealth.ptvHealthSummary = data.data
            }
        );
        this.subscribe(
            { target: 'activity-id-milestone' },
            data => {
                this.activityIdMilestone = new Activity(data.data, { relations: ['enteredBy'] })
                this.headerMilestoneText = data.data.maintext
                this.secondaryMilestoneText = data.data.secondarytext
                this.tertriaryMilestoneText = data.data.tertriarytext
                this.imagePopupPath = data.data.picpath
            }
        );
        this.subscribe(
            { target: 'dispute-assigned-count', user: this.props.store.currentUser.id },
            data => {
                this.assignedDisputesCount = data.data.count;
            }
        );
        this.subscribe(
            { target: 'parkings-count', },
            data => {
                this.parkingsCount = data.data.count;
            }
        )
    }


    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    bepTicketCreated(payload) {
        const user = this.props.store.currentUser;
        if (payload.data.followup_action_taker === user.id) {
            this.bepTicketCounts.total++;
            if (moment(payload.data.followup_action_date).isBefore(moment())) {
                this.bepTicketCounts.overdue++;
            }
        }
    }

    bepTicketUpdated(payload) {
        /*
         * There are three update situations:
         *
         * 1) Follow up action taker is set from someone else to us: total++
         * 2) Follow up action taker is set from us to someone else: total--
         * 3) Follow up action taker is us before and after: total unchanged.
         *
         * Within 1) and 3) we have three options:
         * 4) Follow up action date decreased to make it overdue: overdue++
         * 5) Follow up action date increased to make it not overdue: overdue-- in situation 3), unchanged in situation 1)
         * 6) Follow up action date stays the same: overdue unchanged.
         *
         * Within 2), we have these only two options:
         * 7) Old follow up action date was overdue: overdue--
         * 8) Old follow up action date was not overdue: overdue unchanged
         *
         * TODO: Decrease counts when finished_at is filled in and increase when it is removed
         */
        const user = this.props.store.currentUser;
        console.log('--->', payload.data.followup_action_taker, user.id);
        if (payload.data.followup_action_taker === user.id) {
            if (payload.data.old.followup_action_taker !== user.id) {
                this.bepTicketCounts.total++;
                if (moment(payload.data.followup_action_date).isBefore(moment())) {
                    this.bepTicketCounts.overdue++;
                }
            } else {
                if (moment(payload.data.followup_action_date).isBefore(moment()) && !moment(payload.data.old.followup_action_date).isBefore(moment())) {
                    this.bepTicketCounts.overdue++;
                } else if (!moment(payload.data.followup_action_date).isBefore(moment()) && moment(payload.data.old.followup_action_date).isBefore(moment())) {
                    this.bepTicketCounts.overdue--;
                }
            }
        } else if (payload.data.old.followup_action_taker === user.id) {
            if (payload.data.followup_action_taker !== user.id) {
                this.bepTicketCounts.total--;
            }

            if (moment(payload.data.old.followup_action_date).isBefore(moment())) {
                this.bepTicketCounts.overdue--;
            }
        }
    }

    renderOperation = () => {
        return (
            <NavMenu>
                {this.hasPermission(['activity.manage_activity:all', 'activity.manage_allocation:all']) && (
                    <NavItem
                        title={t('nav.operation.activityPlanning')}
                        to="/operation/activity-planning"
                    />
                )}
                {this.hasPermission(['activity.manage_allocation:all']) && (
                    <NavItem
                        title={t('nav.operation.dispatcher')}
                        to="/operation/dispatcher"
                    />
                )}
                {this.hasPermission(['asset.manage_assignment:all']) && (
                    <NavItem
                        title={t('nav.operation.assetPlanning')}
                        to="/operation/asset-planning"
                    />
                )}
                {this.hasPermission(['activity.view_activity:all', 'activity.manage_activity:all', 'activity.manage_allocation:all', 'asset.manage_assignment:all']) && (
                    <NavItem
                        title={t('nav.operation.maps')}
                        to="/operation/maps"
                    />
                )}
                {this.hasPermission(['boekestijn.manage_parking:dispatcher', 'boekestijn.manage_parking:all']) && (
                    <div style={{display: 'flex', position: 'relative'}}>
                        {this.parkingsCount > 0 && <Label data-test-parking-counter style={{order: '2', top: '1px', left: '90px'}} circular floating color='red' size="tiny">{this.parkingsCount}</Label>}
                        <NavItem
                            title={t('nav.operation.parkingOverview')}
                            to="/operation/parking/overview"
                        />
                    </div>

                )}
            </NavMenu>
        );
    };

    renderAdministration = () => {
        return (
            <NavMenu>
                {this.hasPermission([
                    'invoice.view_invoice:all',
                    'invoice.manage_invoice:all',
                    'invoice.manage_invoice:customer_service'
                ]) && (
                    <NavItem
                        title={t('nav.administration.invoiceOverview')}
                        to="/administration/invoice/overview"
                    />
                )}
                {this.hasPermission([
                    'invoice.view_invoice:all',
                    'invoice.manage_invoice:all',
                    'invoice.manage_invoice:customer_service'
                ]) && (
                    <NavItem
                        title={t('nav.administration.creditOverview')}
                        to="/administration/credit/overview"
                    />

                )}
                {this.hasPermission(['activity.manage_allocation:all']) && (
                    <NavItem
                        title={t('nav.administration.csEmployee')}
                        to="/administration/csemployee"
                    />
                )}
                {this.hasPermission([
                    'invoice.view_invoice:all',
                    'invoice.manage_invoice:all',
                    'invoice.manage_invoice:customer_service',
                    'invoice.manage_dispute:own',
                ]) && (
                    <NavItem
                        title={t('nav.administration.markedActivities')}
                        to="/administration/dispute/overview"
                    />
                )}
                {this.hasPermission([
                    'invoice.view_entityinvoice:all',
                    'invoice.manage_entityinvoice:all',
                ]) && (
                    <NavItem
                        title={t('nav.administration.entityInvoices')}
                        to="/administration/entity-invoices"
                    />
                )}
                {this.hasPermission([
                    'invoice.view_invoice:all',
                    'invoice.manage_invoice:all',
                    'invoice.manage_invoice:customer_service'
                ]) && (
                    <NavItem
                        title={t('nav.administration.costOverview')}
                        to="/administration/cost/overview"
                    />
                )}
                {this.hasPermission(['iso_quality.manage_bepticket:all']) && (
                    <NavItem
                        title={t('nav.administration.bepTickets')}
                        to="/administration/bep-tickets"
                    />
                )}

                {false && ( // TODO: Remove all this dead code
                    <NavItem
                        title={t('nav.administration.waivers')}
                        to="/administration/waiver/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_costinvoice') && (
                    <NavItem
                        title={t('nav.archive.costInvoiceOverview')}
                        to="/administration/cost-invoice/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_fuelcost') && (
                    <NavItem
                        title={t('nav.archive.fuelCostOverview')}
                        to="/administration/fuel-cost/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_roadcost') && (
                    <NavItem
                        title={t('nav.archive.roadCostOverview')}
                        to="/administration/road-cost/overview"
                    />
                )}
                {this.hasPermission(['boekestijn.authorize_exactonlineapisetting']) && (
                    <ExactOnlineAuth
                        title={t('nav.administration.exactAuth')}
                        to="/api/exact_online_api_setting/oauth/"
                    />
                )}
            </NavMenu>
        );
    };


    renderDriverCare = () => {
        return (
            <NavMenu>
                {this.hasPermission(['recruitment.manage_driverlead:all', 'recruitment.manage_driverlead:recruitment_employee']) && (
                    <NavItem
                        title="Recruitment"
                        to="/driver-care/recruitment"
                    />
                )}
                {this.hasPermission(['asset.manage_driver:driver_care', 'asset.manage_driver:all', 'asset.manage_driver:recruitment_employee',]) && (
                    <NavItem
                        title="In Progress"
                        to="/driver-care/in-progress"
                    />
                )}
                {this.hasPermission(['asset.manage_driver:driver_care', 'asset.manage_driver:all']) && (
                    <NavItem
                        title="Driver Care"
                        to="/driver-care/manager"
                    />
                )}
                {this.hasPermission(['claim.manage_incident:all']) && (
                    <NavItem
                        title="Claims"
                        to="/driver-care/incident/overview"
                    />
                )}
                {this.hasPermission(['claim.view_incident:viewer']) && (
                    <NavItem
                        title="Claim Viewer"
                        to="/driver-care/incident/overview"
                    />
                )}

            </NavMenu>
        );
    };

    renderArchive = () => {
        return (
            <NavMenu>
                {this.hasPermission(['activity.view_activity:customer_service', 'activity.manage_activity:all', 'activity.export_activity:all']) && (
                    <NavItem
                        title={t('nav.archive.activities')}
                        to="/archive/activities"
                    />
                )}
                {this.hasPermission(['activity.manage_activity:all']) && (
                    <NavItem
                        title={t('nav.archive.messages')}
                        to="/archive/messages"
                    />
                )}
                {this.hasPermission(['activity.manage_activity:all']) && (
                    <NavItem
                        title={t('nav.archive.truckPositions')}
                        to="/archive/truck-positions"
                    />
                )}
            </NavMenu>
        );
    };

    renderMasterdata = () => {
        return (
            <NavMenu>
                {this.hasPermission(['customer.manage_customer:all', 'customer.manage_customer:cs_employee']) && (
                    <NavItem
                        title={t('nav.masterdata.customerOverview')}
                        to="/masterdata/customer"
                    />
                )}
                {/* Disabled, since now driver-care/manager does this as well. */}
                {false && this.hasPermission(['asset.manage_driver:all']) && (
                    <NavItem
                        title={t('nav.masterdata.driverOverview')}
                        to="/masterdata/driver"
                    />
                )}
                {this.hasPermission(['cost.manage_assumption:all']) && (
                    <NavItem
                        title={t('nav.masterdata.assumptions')}
                        to="/masterdata/assumption"
                    />
                 )}
                {this.hasPermission(['asset.manage_driverbonus:all']) && (
                    <NavItem
                        title={t('nav.masterdata.driverBonus')}
                        to="/masterdata/driver-bonus/overview"
                    />
                )}
                {this.hasPermission(['customer.manage_trailer:all']) && (
                    <NavItem
                        title={t('nav.masterdata.trailerOverview')}
                        to="/masterdata/trailer"
                    />
                )}
                {this.hasPermission(['asset.manage_truck:all', 'asset.view_truck:all']) && (
                    <NavItem
                        title={t('nav.masterdata.truckOverview')}
                        to="/masterdata/truck"
                    />
                )}
                {this.hasPermission(['boekestijn.manage_supplier:all']) && (
                    <NavItem
                        title={t('nav.masterdata.supplierOverview')}
                        to="/masterdata/supplier"
                    />
                )}
                {/* Those two below not in use/not needed anymore */}
                {false && this.hasPermission(['boekestijn.manage_fleetvisor:all']) && (
                    <NavItem
                        title={t('nav.masterdata.fleetvisorFormOverview')}
                        to="/masterdata/fleetvisor-form"
                    />
                )}
                {false && this.hasPermission(['boekestijn.manage_transics:all']) && (
                    <NavItem
                        title={t('nav.masterdata.transicsFormOverview')}
                        to="/masterdata/transics-form"
                    />
                )}
                {this.hasPermission(['boekestijn.manage_excise:all']) && (
                    <NavItem
                        title={t('nav.masterdata.exciseOverview')}
                        to="/masterdata/excise/overview"
                    />
                )}
                {this.hasPermission(['boekestijn.manage_petrolstation:all', 'boekestijn.manage_petrolstation:operations']) && (
                    <NavItem
                        title={t('nav.masterdata.petrolStationOverview')}
                        to="/masterdata/petrol-station/overview"
                    />
                )}
                {this.hasPermission(['asset.manage_assignment:all']) && (
                    <NavItem
                        title={t('nav.masterdata.conditionInstructionOverview')}
                        to="/masterdata/conditionInstruction/overview"
                    />
                )}
                {this.hasPermission(['customer.manage_customer:all']) && (
                    <NavItem
                        title={t('nav.masterdata.equipmentTypeOverview')}
                        to="/masterdata/equipment-type/overview"
                    />
                )}
                {this.hasPermission(['iso_quality.manage_bepticketcategory:all']) && (
                    <NavItem
                        title={t('nav.masterdata.bepTicketCategories')}
                        to="/masterdata/bep-ticket-categories"
                    />
                )}
                {this.hasPermission(['iso_quality.manage_bepticketcategory:all']) && (
                    <NavItem
                        title={t('nav.masterdata.bepTicketSubcategoryOverview')}
                        to="/masterdata/bep-ticket-subcategory"
                    />
                )}
                {this.hasPermission(['iso_quality.manage_bepticketcategory:all']) && (
                    <NavItem
                        title={t('nav.masterdata.bepFollowupActionCategories')}
                        to="/masterdata/bep-followup-action-categories"
                    />
                )}
                {this.hasPermission(['recruitment.manage_driverlead:all']) && (
                    <NavItem
                        title="Recruitment campaigns"
                        to="/masterdata/lead-generation-campaign/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_ledgercost') && (
                    <NavItem
                        title={t('nav.masterdata.ledgerCostOverview')}
                        to="/masterdata/ledger-cost/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_ledgervat') && (
                    <NavItem
                        title={t('nav.masterdata.ledgerVatOverview')}
                        to="/masterdata/ledger-vat/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_costsupplier') && (
                    <NavItem
                        title={t('nav.masterdata.costSupplierOverview')}
                        to="/masterdata/cost-supplier/overview"
                    />
                )}
                {hasLowLevelPermission('cost.view_costproduct') && (
                    <NavItem
                        title={t('nav.masterdata.costProductOverview')}
                        to="/masterdata/cost-product/overview"
                    />
                )}
                 {this.hasPermission(['asset.manage_rejectionreason:all']) && (
                    <NavItem
                        title={t('nav.masterdata.rejectionReason')}
                        to="/masterdata/rejection-reason/overview"
                    />
                )}
                {hasLowLevelPermission('asset.change_lisintegration') && (
                    <NavItem
                        title={t('nav.masterdata.lisIntegrationOverview')}
                        to="/masterdata/lis-integration/overview"
                    />
                )}
                {hasLowLevelPermission('asset.change_ecsintegration') && (
                    <NavItem
                        title={t('nav.masterdata.ecsIntegrationOverview')}
                        to="/masterdata/ecs-integration/overview"
                    />
                )}
            </NavMenu>
        );
    };

    renderAdmin = () => {
        return (
            <NavMenu>
                {this.hasPermission(['boekestijn.manage_profile:all']) && (
                    <NavItem
                        title={t('nav.admin.userOverview')}
                        to="/admin/user"
                    />
                )}
                {this.hasPermission(['asset.manage_truckbrand:all']) && (
                    <NavItem
                        title={t('nav.admin.truckBrandOverview')}
                        to="/admin/truck-brand"
                    />
                )}
                {this.hasPermission(['asset.manage_certification:all']) && (
                    <NavItem
                        title={t('nav.admin.certificationOverview')}
                        to="/admin/certification/overview"
                    />
                )}
                {this.hasPermission(['asset.manage_license:all']) && (
                    <NavItem
                        title={t('nav.admin.licenseOverview')}
                        to="/admin/license/overview"
                    />
                )}
                {this.hasPermission(['activity.manage_cashrequest:all']) && (
                    <NavItem
                        title={t('nav.admin.cashRequest')}
                        to="/admin/cash-request/overview"
                    />
                )}
                {this.hasPermission(['recruitment.manage_driverlead:all']) && (
                    <NavItem
                        title="Recruitment channels"
                        to="/admin/lead-generation-channel/overview"
                    />
                )}
                {this.hasPermission(['customer.manage_entity:all']) && (
                    <NavItem
                        title={t('nav.masterdata.entityOverview')}
                        to="/admin/entity"
                    />
                )}
                {this.hasPermission(['customer.manage_entity:all']) && (
                    <NavItem
                        title="Payroll Companies"
                        to="/admin/payroll-company/overview"
                    />
                )}
                {hasLowLevelPermission(['activity.add_tachoevent']) && (
                    <NavItem
                        title="Tacho Events"
                        to="/admin/tacho-event/overview"
                    />
                )}
                {this.hasPermission(['asset.manage_tollboxsupplier:all']) && (
                    <NavItem
                        title="Tollbox Suppliers"
                        to="/admin/tollbox-supplier/overview"
                    />
                )}
                {this.hasPermission(['customer.manage_entity:all']) && (
                    <NavItem
                        title="Contract Types"
                        to="/admin/contract-type/overview"
                    />
                )}
                {this.hasPermission(['asset.manage_homebase:all']) && (
                <NavItem
                    title={t('nav.masterdata.homebaseOverview')}
                    to="/admin/homebase/overview"
                />
                )}
            </NavMenu>
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <NavItem
                    title={t('nav.account.changePassword')}
                    to="/account/password"
                />
                {!this.props.store.currentUser.isCustomer && (
                    <React.Fragment>
                        <NavItem
                            title={t('nav.account.systemHealth')}
                            to="/account/system-health"
                        />
                        <NavItem title={t('nav.account.changelog')} to="/account/changelog" />
                    </React.Fragment>
                )}
            </NavMenu>
        );
    };

    renderOther = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.main.bep.ownTickets')}
                    to="/other/own-bep-tickets/overview"
                />
                {(this.hasPermission(['invoice.manage_dispute:operations']) || this.hasPermission(['invoice.manage_dispute:dispatcher'])) && (
                    <NavItem
                        title={t('nav.operation.dispute')}
                        to="/other/operation-dispute/overview"
                    />
                )}
            </NavMenu>
        )
    }

    renderActivityMilestone = () => {
        if (this.activityIdMilestone) {
            let combinedMilestoneText = [this.secondaryMilestoneText, this.activityIdMilestone.id, this.tertriaryMilestoneText, this.activityIdMilestone.enteredBy.fullName].join('')
            return (
                <Modal
                    size="large"
                    open={!!this.activityIdMilestone}
                    centered={false}
                    onClose={() => this.activityIdMilestone = null}
                >
                    <Modal.Header>{this.headerMilestoneText}</Modal.Header>
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <span data-test-milestone-text>{combinedMilestoneText}</span>
                        <br />
                        <br />
                        <Image centered src={this.imagePopupPath} />
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex' }}>
                        <RightDivider />
                        <SubmitButton primary content="OK!" onClick={() => this.activityIdMilestone = null} />
                    </Modal.Actions>
                </Modal>
            );
        }
    }

    render() {
        if (!this.props.store.isAuthenticated) {
            return null;
        }

        const { currentUser } = this.props.store;
        const { version } = BUILD_INFO;
        const accountTitle = `${currentUser.fullName} (${version})`;
        const account = (
            <NavItem
                title={(
                    <React.Fragment>
                        {currentUser._isMasqueraded && <StyledIconButton color="red" name="user secret" />}
                        {accountTitle}
                        {this.systemHealth.fleetvisorColor !=='green' && (
                            <Popup
                                exclamation-triangle-popup
                                triangle position="bottom right"
                                content={this.systemHealth.fleetvisorPopupMessage}
                                trigger={
                                    <StyledIconButton
                                        data-test-fleetvisor-unprocessed-msg-count-icon-header
                                        size='large' name="exclamation triangle"
                                        color={this.systemHealth.fleetvisorColor}
                                    />
                                }
                            >
                            <p>{t('systemHealth.fleetvisorPopup.title')}</p>
                                <Table collapsing definition>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.fleetvisorPopup.currentMailboxSize')}</Table.Cell>
                                        <Table.Cell data-test-fleetvisor-avg-request-time>{this.systemHealth.fleetvisorCurrentMailboxSize}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.fleetvisorPopup.avgMailboxCount')}</Table.Cell>
                                        <Table.Cell data-test-fleetvisor-avg-request-time>{this.systemHealth.fleetvisorAvgMailboxCount}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.fleetvisorPopup.errorCount')}</Table.Cell>
                                        <Table.Cell data-test-fleetvisor-error-count>{this.systemHealth.fleetvisorErrorCount}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.fleetvisorPopup.totalCount')}</Table.Cell>
                                        <Table.Cell data-test-fleetvisor-total-count>{this.systemHealth.fleetvisor.length}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.fleetvisorPopup.avgRequestTime')}</Table.Cell>
                                        <Table.Cell data-test-fleetvisor-total-count>{this.systemHealth.fleetvisorAvgRequestTime}</Table.Cell>
                                    </Table.Row>
                                </Table>
                            </Popup>
                        )}
                        {this.systemHealth.ptvHealthColor !=='green' && (
                            <Popup
                                size="large"
                                position="bottom right"
                                trigger={
                                    <StyledIconButton data-test-ptv-health
                                        size="large"
                                        name="exclamation triangle"
                                        color={this.systemHealth.ptvHealthColor}
                                    />
                                }
                            >
                                <p>{t('systemHealth.ptvHealthPopup.title')}</p>
                                <Table collapsing definition>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.ptvHealthPopup.avgRequestTime')}</Table.Cell>
                                        <Table.Cell data-test-ptv-health-avg-request-time>{this.systemHealth.ptvHealthAvgRequestTime}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.ptvHealthPopup.errorCount')}</Table.Cell>
                                        <Table.Cell data-test-ptv-health-error-count>{this.systemHealth.ptvHealthErrorCount}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{t('systemHealth.ptvHealthPopup.totalCount')}</Table.Cell>
                                        <Table.Cell data-test-ptv-health-total-count>{this.systemHealth.ptvHealthTotalRequestTracked}</Table.Cell>
                                    </Table.Row>
                                </Table>
                            </Popup>
                        )}
                    </React.Fragment>
                )}
                to="/account/details"
            />
        );

        const accountSubmenu = (
            <Route path="/account" render={this.renderAccount} />
        );

        return (
            <TopMenu>
                <MenuRow>
                    <MyLogo isLoggedIn={true} />
                    <NavMenu>
                        {this.hasPermission([
                            'activity.manage_assignment:all',
                            'activity.manage_activity:all',
                        ]) && (
                            <NavItem
                                title={t('nav.main.operation')}
                                to={this.hasPermission(['activity.view_activity:all',
                                                        'activity.manage_activity:all',

                                                        'activity.manage_allocation:all'])
                                    ? '/operation/activity-planning' : '/operation/activities'}
                                activePath="/operation/"
                            />
                        )}
                        {this.hasPermission([
                            'invoice.manage_invoice:customer_service',
                            'customer.manage_customer:all',
                            'invoice.view_invoice:all',
                            'invoice.manage_invoice:all',
                        ]) && (
                            <NavItem
                                title={t('nav.main.administration')}
                                to={this.hasPermission(['invoice.manage_invoice:customer_service',
                                                        'invoice.manage_invoice:all'])
                                    ? '/administration/invoice/overview' : '/administration/csemployee'}
                                activePath="/administration/"
                            />
                        )}
                        {this.hasPermission([
                            'asset.manage_driver:driver_care',
                            'asset.manage_driver:all',
                            'recruitment.manage_driverlead:all',
                            'claim.manage_incident:all',
                            'claim.view_incident:viewer',
                            'recruitment.manage_driverlead:recruitment_employee',
                        ]) && (
                            <NavItem
                                title={t('nav.main.driverCare')}
                                activePath="/driver-care/"
                                to={() => {
                                    let redirect = ''
                                    if (this.hasPermission(['asset.manage_driver:driver_care', 'asset.manage_driver:all'])) {
                                        redirect = '/driver-care/manager'
                                    } else if (this.hasPermission(['recruitment.manage_driverlead:recruitment_employee'])) {
                                        redirect = '/driver-care/recruitment'
                                    } else {
                                        redirect = '/driver-care/incident/overview'
                                    }
                                    return redirect
                                }}
                            />
                        )}
                        {this.hasPermission(['activity.view_activity:customer_service', 'activity.manage_activity:all', 'activity.export_activity:all']) && (
                            <NavItem
                                title={t('nav.main.archive')}
                                to={this.hasPermission(['activity.export_activity:all', 'activity.view_activity:customer_service'])
                                    ? '/archive/activities' : '/archive/messages'}
                                activePath="/archive/"
                            />
                        )}
                        {this.hasPermission([
                            'customer.manage_customer:all',
                            'asset.manage_driver:all',
                            'asset.manage_truck:all',
                            'customer.manage_trailer:all',
                            'boekestijn.manage_supplier:all',
                            'iso_quality.manage_bepticketcategory:all',
                            'customer.manage_entity:all',
                            'boekestijn.manage_excise:all',
                            'cost.manage_assumption:all',
                            'customer.manage_customer:cs_employee'
                        ]) && (
                            <NavItem
                                title={t('nav.main.masterdata')}
                                to="/masterdata/"
                                activePath="/masterdata/"
                            />
                        )}
                        {this.hasPermission([
                            'boekestijn.manage_profile:all',
                            'customer.manage_truckbrand:all',
                            'customer.manage_certification:all',
                            'customer.manage_license:all',
                        ]) && (
                            <NavItem
                                title={t('nav.main.admin')}
                                to="/admin/"
                                activePath="/admin/"
                            />
                        )}
                        </NavMenu>
                        {(this.hasPermission(['invoice.manage_dispute:operations']) || this.hasPermission(['invoice.manage_dispute:dispatcher'])) &&
                            <StyledLink to="/other/operation-dispute/overview">
                                <StyledIconButton
                                    data-test-requested-disputes
                                    name="bell outline"
                                    title={t('nav.operation.dispute')}
                                >
                                    {this.assignedDisputesCount > 0 && !currentUser.inGroup('admin') && !currentUser.isSuperuser && (
                                            <StyledLabelInPopup floating circular
                                                size="mini"
                                                color="yellow"
                                                data-test-requested-disputes-count={this.assignedDisputesCount}
                                            >
                                                {this.assignedDisputesCount}
                                            </StyledLabelInPopup>
                                        )}
                                </StyledIconButton>
                            </StyledLink>
                        }
                        {this.hasPermission(['iso_quality.manage_bepticket:own', 'iso_quality.manage_bepticket:all']) && (
                            <React.Fragment>
                                <StyledIconButton
                                    name="lightbulb outline"
                                    title={t('nav.main.bep.idea')}
                                    onClick={() => this.bepModalIdeaOpen = true}
                                />
                                <StyledIconButton
                                    name="thumbs up outline"
                                    title={t('nav.main.bep.positive')}
                                    onClick={() => this.bepModalPositiveOpen = true}
                                />
                                <StyledIconButton
                                    name="thumbs down outline"
                                    title={t('nav.main.bep.negative')}
                                    onClick={() => this.bepModalNegativeOpen = true}
                                />
                                <StyledLink to="/other/own-bep-tickets/overview">
                                    <StyledIconButton
                                        name="clipboard list"
                                        title={t('nav.main.bep.ownTickets')}
                                    >
                                        {this.bepTicketCounts.total > 0 && (
                                            <StyledLabelInPopup floating circular
                                                size="mini"
                                                color={this.bepTicketCounts.overdue > 0 ? 'red' : 'yellow'}
                                            >
                                                {this.bepTicketCounts.total}
                                            </StyledLabelInPopup>
                                        )}
                                    </StyledIconButton>
                                </StyledLink>
                                <Modal
                                    open={this.bepModalIdeaOpen}
                                    onClose={() => this.bepModalIdeaOpen = false}
                                >
                                    <CreateBEPDialog
                                        meaning={MEANING_IDEA}
                                        currentUser={this.props.store.currentUser}
                                        close={() => this.bepModalIdeaOpen = false}
                                    />
                                </Modal>
                                <Modal open={this.bepModalPositiveOpen}
                                       onClose={() => this.bepModalPositiveOpen = false}
                                >
                                    <CreateBEPDialog
                                        meaning={MEANING_POSITIVE}
                                        currentUser={this.props.store.currentUser}
                                        close={() => this.bepModalPositiveOpen = false}
                                    />
                                </Modal>
                                <Modal open={this.bepModalNegativeOpen}
                                       onClose={() => this.bepModalNegativeOpen = false}
                                >
                                    <CreateBEPDialog
                                        meaning={MEANING_NEGATIVE}
                                        currentUser={this.props.store.currentUser}
                                        close={() => this.bepModalNegativeOpen = false}
                                    />
                                </Modal>
                            </React.Fragment>
                        )}
                    {false && this.hasPermission('boekestijn.manage_profile:all') && (
                        <StyledButton tone={this.debug ? 'danger' : undefined} onClick={this.toggleDebug}>
                            {this.debug ? t('disableDebug') : t('enableDebug')}
                        </StyledButton>
                    )}
                    {account}
                    {this.isAnniversary && <LogoRDImg data-test-logo-anniversary src={RDAnniverary} />}
                    {this.renderActivityMilestone()}
                </MenuRow>
                <MenuRow>
                    <Route path="/operation" render={this.renderOperation} />
                    <Route path="/administration" render={this.renderAdministration} />
                    <Route path="/archive" render={this.renderArchive} />
                    <Route path="/masterdata" render={this.renderMasterdata} />
                    <Route path="/admin" render={this.renderAdmin} />
                    <Route path="/driver-care" render={this.renderDriverCare} />
                    <Route path="/other" render={this.renderOther} />
                    {accountSubmenu}
                </MenuRow>
            </TopMenu>
        );
    }
}
