import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { LocationWithoutPoint } from './Location';
import { User } from './User';
import { LisIntegration } from './LisIntegration';
import { EcsIntegration } from './EcsIntegration';
import { ContractStore } from './Contract';
import { ContactStore } from './Contact';
import { some, omit } from 'lodash';

export const AFTER_OFFICE_HOURS_EMAIL = 'email';
export const AFTER_OFFICE_HOURS_ONLY_CALL = 'only call';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id = null;
    @observable name = '';
    @observable chamberOfCommerce = '';
    @observable vatCode = '';
    @observable iban = '';
    @observable phone = '';
    @observable exactOnlineCode = null;
    // @observable lisUrl = '';
    // @observable lisLocalPath = '';
    // @observable lisActivityLinkRequiredForFinalize = true;
    @observable emailFromName = 'Boekestijn Trucking';
    @observable emailFromAddress = 'trucking@boekestijntransport.com';
    @observable emailFromNameDocumentsInvoices = 'Customer Service (Boekestijn Transport Service)';
    @observable emailFromAddressDocumentsInvoices = 'cspl@boekestijntransport.com';
    @observable updateMailToAddress = '';
    @observable lastUpdateSentAt = null;
    @observable updateInterval = 0;
    @observable updateMailTemplate = null;
    @observable _updateMailTemplateFile = null; // actual file blob
    @observable canCreateTrailer = false;
    @observable useCustomerIdForUpdate = true;
    @observable paymentTerm = 60;
    @observable creditLimitAmount = 1000000; // in cents
    @observable creditLimitExpiryDate = null;
    @observable externalDebtorCreditorNumber = '';

    @observable openingHoursFrom = '';
    @observable openingHoursTill = '';
    @observable afterOfficeHours = AFTER_OFFICE_HOURS_EMAIL;

    @observable amountDue = 0;
    @observable toBeInvoiced = 0;
    @observable deactivatedAt = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable isDfdsIntegrationEnabled = false;
    @observable isKtaIntegrationEnabled = false;
    @observable isDpg2IntegrationEnabled = false;

    @observable activityLinkRequiredForFinalizeDpg2 = false;
    @observable orderInstructions = '';
    @observable activitySummary = '';

    @observable _lisIntegrationId = null; // Small hack to avoid fetching lis integration everywhere.
    @observable _ecsIntegrationId = null; // Small hack to avoid fetching ecs integration everywhere.
    @observable autoFinalize = false

    @observable isCmrRequestedForLoad = false
    @observable isCmrRequestedForTrailerDrop = false

    @observable sendPodsToTeamsChannel = false;


    toBackend(options) {
        const data = super.toBackend(options);
        return omit(data, ['to_be_invoiced']);
    }

    /**
     * Parse lis_integration id for performance.
     */
    fromBackend({ data = {}, ...rest }) {
        data._lisIntegrationId = data.lis_integration;
        data._ecsIntegrationId = data.ecs_integration;

        return super.fromBackend({ data, ...rest });
    }

    casts() {
        return {
            lastUpdateSentAt: Casts.datetime,
            creditLimitExpiryDate: Casts.date,
            deactivatedAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    saveFiles() {
        // Currently only the "updateMailTemplate" file, but there may be more...
        const file = this._updateMailTemplateFile;
        if (!file) {
            return;
        }
        const data = new FormData();
        data.append('update_mail_template', file, file.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}update_mail_template/`, data, {
            headers,
        });
    }

    relations() {
        return {
            invoiceLocation: LocationWithoutPoint, // Quickfix for error 500.
            visitingLocation: LocationWithoutPoint, // Quickfix for error 500.
            accountManager: User,
            contracts: ContractStore,
            contacts: ContactStore,
            lisIntegration: LisIntegration,
            ecsIntegration: EcsIntegration,
        };
    }

    sendMailUpdate(contract) {
        this.__pendingRequestCount += 1;
        const data = { 'contract': contract.toJS() }
        return this.api
            .post(`${this.url}send_overall_status_update_mail/`, data, {})
            .then((res) => {
                this.__pendingRequestCount -= 1;
                return res;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    deactivate() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}deactivate/`)
            .then(() => {
                window.location = '/masterdata/customer';
            })
        );
    }

    activate() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}activate/`)
            .then(() => {
                window.location = '/masterdata/customer';
            })
        );
    }

    @computed
    get isLisEnabled() {
        if (this.__activeRelations.includes('lisIntegration')) {
            return !this.lisIntegration.isNew;
        }

        return !!this._lisIntegrationId;
    }

    @computed
    get isEcsEnabled() {
        if (this.__activeRelations.includes('ecsIntegration')) {
            return !this.ecsIntegration.isNew;
        }

        return !!this._ecsIntegrationId;
    }

    @computed
    get downloadUpdateMailTemplateUrl() {
        return `${this.api.baseUrl.replace(/\/$/, '')}${
            this.url
        }update_mail_template/?download`;
    }

    @computed
    get nameShort() {
        return this.name.substring(0, 5);
    }

    @computed
    get creditRisk() {
        const risk = this.amountDue + this.toBeInvoiced - this.creditLimitAmount;

        if (risk < 0) {
            return 0;
        }

        return risk;
    }
}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';

    findTariffById(id) {
        let tariff = undefined;

        some(this.models, customer =>
            some(customer.contracts.models, contract =>
                some(contract.tariffs.models, aTariff => {
                    if (aTariff.id === id) {

                        tariff = aTariff;
                        return true;
                    }
                })
            )
        );

        return tariff;
    }

    login(token) {
        this.__pendingRequestCount += 1;

        // Spider doesnt pass options to actual xhr, so Im passing Authorization header this way.
        this.api.defaultHeaders['Authorization'] = `Bearer ${token}`;
        const res = this.api
            .post(`${this.url()}login/`, {
                token
            })
            .then(() => {
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });

        // We don't need that anymore, since we got session from django
        delete this.api.defaultHeaders['Authorization'];
        return res;
    }
}
